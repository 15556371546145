import Layout from '../components/Layout'
import React from 'react'
import SEO from '../components/seo'
import { sessionStore } from 'utils/StorageUtils'
import scrollTo from 'gatsby-plugin-smoothscroll'
import { createUseStyles } from 'react-jss'
import { useTranslation } from 'react-i18next'

const useStyles = createUseStyles((theme) => {
  return {
    notFoundWrapper: {
      height: 'calc(100vh - 513px)',
      paddingTop: 76,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }
})

const NotFoundPage = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  React.useEffect(() => {
    const partnerId = sessionStore?.getItem('partnerId')
    if (partnerId) {
      setTimeout(() => scrollTo('#gatsby-focus-wrapper'), 0)
    }
  }, [])

  return (
    <Layout>
      <SEO title="404: Not found" />
      <div className={classes.notFoundWrapper}>
        <h1>{t('common:NOT_FOUND_TITLE')}</h1>
        <p>{t('common:NOT_FOUND_DESCRIPTION')}</p>
      </div>
    </Layout>
  )
}

export default NotFoundPage
